<template>
    <div>
        <section class="hero is-medium is-dark has-background">
            <img :src="`https://swissdominoeffect.com/img/headers/${header_image}`" class="is-transparent hero-background" />
            <div class="hero-body">
                <div class="container">
                    <h1 class="title is-1 is-size-2-mobile">
                        Kontakt aufnehmen
                    </h1>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="content is-medium" v-if="!is_sent">
                    <p class="subtitle is-4">
                        Gerne sind wir bereit mit Ihnen in Kontakt zu treten. Bei Fragen zu unseren
                        Dienstleistungen
                        oder anderen Anliegen stehen wir Ihnen gerne zur Verfügung.
                    </p>
                    <div class="columns is-multiline">
                        <div class="column is-8 is-mobile-12">
                            <b-field label="Schule / Organisation">
                                <b-input v-model="form.company" size="is-medium"></b-input>
                            </b-field>

                            <b-field label="Anrede" :type="field_type('salutation')">
                                <b-select placeholder="Anrede auswählen" v-model="form.salutation" size="is-medium" required>
                                    <option value="Herr">Herr</option>
                                    <option value="Frau">Frau</option>
                                </b-select>
                            </b-field>

                            <b-field label="Vorname" :type="field_type('firstname')">
                                <b-input v-model="form.firstname" size="is-medium" required></b-input>
                            </b-field>

                            <b-field label="Nachname" :type="field_type('surname')">
                                <b-input v-model="form.surname" size="is-medium" required></b-input>
                            </b-field>

                            <b-field label="Email" :type="field_type('email')">
                                <b-input type="email" v-model="form.email" maxlength="60" size="is-medium" required>
                                </b-input>
                            </b-field>

                            <b-field label="Telefon">
                                <b-input type="telephone" v-model="form.telephone" size="is-medium" maxlength="20">
                                </b-input>
                            </b-field>

                            <b-field label="Nachricht" :type="field_type('message')">
                                <b-input maxlength="500" v-model="form.message" type="textarea" size="is-medium" required></b-input>
                            </b-field>

                            <div class="notification is-danger" v-if="error">
                                <h2 class="title is-3">Es ist ein Fehler aufgetreten</h2>
                                <p>{{error}}</p>
                                <p>Bitte versuchen Sie es später erneut oder nehmen Sie per E-Mail mit uns Kontakt auf.
                                </p>
                            </div>
                            <div class="is-clearfix">
                            <button class="button is-primary is-medium is-outlined is-pulled-right" @click="submit"
                                v-bind:class="{'is-loading': is_sending}">Nachricht
                                versenden</button>
                            </div>
                        </div>

                        <div class="column is-4 is-mobile-12">
                            <div class="notification">
                                <h4 class="title is-4">Kontaktdetails</h4>
                                <p>Swiss Domino Effect GmbH<br>
                                    Daniel Huwiler & Jonas Oswald<br>
                                    Tohuebstrasse 2<br>
                                    8580 Hefenhofen
                                    <hr>
                                    <a href="mailto:info@swissdominoeffect.com">info@swissdominoeffect.com</a><br>
                                    <a href="tel:+41764340082">076 434 00 82</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content is-medium" v-else>
                    <div class="notification is-success">
                        <h2 class="title is-3">Ihre Nachricht wurde gesendet!</h2>
                        <p>Wir haben Ihre Nachricht empfangen und werden uns schnellstmöglich darum kümmern.</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import {
        contact
    } from '@/webapi'
    export default {
        metaInfo: {
            title: 'Kontakt aufnehmen',
            meta: [{
                    name: 'description',
                    content: 'Nehmen Sie jetzt mit Swiss Domino Effect Kontakt auf.'
                },
                {
                    name: 'keywords',
                    content: 'Kontakt aufnehmen, Lageradresse für Dominosteine abholen, Amriswil, Hefenhofen, Telefon, Dominosteine, Schweiz, Daniel Huwiler, Jonas Oswald'
                }
            ],
        },
        data: function () {
            return {
                header_image: 'kontakt_domino_banner.jpg',
                form: {
                    company: '',
                    salutation: '',
                    firstname: '',
                    surname: '',
                    email: '',
                    telephone: '',
                    message: ''
                },
                errors: [],
                error: '',
                is_sent: false,
                is_sending: false
            }
        },
        methods: {
            submit() {
                this.is_sending = true
                if (this.validate()) {
                    contact(this.form).then(resp => {
                        this.is_sent = resp.data.state
                        if (!this.is_sent) {
                            this.error = resp.data.error
                        }
                        this.is_sending = false
                    }).catch(error => {
                        this.error = error
                        this.is_sending = false
                    })
                } else {
                    this.is_sending = false
                }
            },
            validate() {
                this.errors = []
                this.error = ''
                if (!this.form.salutation) {
                    this.errors.push('salutation')
                }

                if (!this.form.firstname) {
                    this.errors.push('firstname')
                }

                if (!this.form.surname) {
                    this.errors.push('surname')
                }

                if (!this.form.email) {
                    this.errors.push('email')
                }

                if (!this.form.message) {
                    this.errors.push('message')
                }
                return this.errors.length == 0
            },
            field_type(id) {
                return this.errors.find(el => el == id) ? 'is-danger' : ''
            }
        }
    }
</script>